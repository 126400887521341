/************
* Variables *
*************/
:root {
  --red: #ed093c;
  --orange: #fa5d35;
  --yellow: #ffb500;
  --green: #32b38b;
  --blue: #5772e5;
  --purple: #6d2acc;
  --purple-darker: #491dac;
  --purple-lighter: #8635d7;
  --grey: #868c95;
  --light-grey: #ddd7d1; }

/************
* Colours   *
*************/
/************
* Basic     *
*************/
body {
  background: #868c95;
  font-weight: 400;
  font-size: 1rem;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  color: #868c95; }

p {
  margin: 0;
  color: #868c95;
  font-size: 1rem; }

a {
  color: #6d2acc; }

a:hover {
  color: #373940; }

a:visited {
  color: #ddd7d1; }

h1 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  color: #868c95;
  padding: 0;
  margin: 0; }

h2 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0; }

h3 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0; }

h4 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0; }

h5 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0; }

.page {
  overflow-x: hidden; }

.text-center {
  text-align: center; }

.font-w-500 {
  font-weight: 500; }

.pad-150 {
  padding-top: 150px;
  padding-bottom: 150px; }

.interviews-pad {
  padding-top: 125px;
  padding-bottom: 125px; }

.w-100 {
  width: 100%; }

.m-b-15 {
  margin-bottom: 15px; }

.m-b-25 {
  margin-bottom: 25px; }

.m-b-40 {
  margin-bottom: 40px; }

.m-b-50 {
  margin-bottom: 50px; }

.m-b-100 {
  margin-bottom: 100px; }

.m-l-0 {
  margin-left: 0px; }

.m-l-25 {
  margin-left: 25px; }

.m-t-15 {
  margin-top: 15px; }

.m-t-25 {
  margin-top: 25px; }

.m-t-25 {
  margin-top: 30px; }

.m-t-40 {
  margin-top: 40px; }

.m-t-50 {
  margin-top: 50px; }

.m-t-75 {
  margin-top: 75px; }

.m-t-100 {
  margin-top: 100px; }

.bg-white {
  background-color: white; }

.bg-black {
  background-color: black; }

.flex-container {
  display: flex;
  margin-right: 12rem;
  margin-left: 12rem; }

@media only screen and (max-width: 1700px) {
  .flex-container {
    margin-right: 4rem;
    margin-left: 4rem; } }

@media only screen and (max-width: 1200px) {
  .flex-container {
    margin-right: 2rem;
    margin-left: 2rem; } }

.flex-vertical {
  display: flex;
  flex-direction: column; }

.flex-horizontal {
  display: flex;
  flex-direction: row; }

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.justify-between {
  display: flex;
  justify-content: space-between; }

.justify-around {
  display: flex;
  justify-content: space-around; }

.justify-center {
  display: flex;
  justify-content: center; }

.align-between {
  display: flex;
  align-items: space-between; }

.align-around {
  display: flex;
  align-items: space-around; }

.align-center {
  display: flex;
  align-items: center; }

/************
* Buttons   *
*************/
.orbit-button {
  padding: 8px 40px 8px 40px;
  margin: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: white;
  background-color: transparent;
  transition: all 0.4s; }
  .orbit-button .fa-button-icon {
    margin-right: 10px; }

.orbit-button:hover {
  background-color: rgba(0, 0, 0, 0.2); }

.orbit-button.primary {
  background-color: #32b38b;
  border: 1.5px solid #32b38b; }

.orbit-button.primary:hover {
  background-color: #258366;
  border: 1.5px solid #258366; }

.orbit-button.danger,
.orbit-button.delete {
  background-color: #ed093c;
  border: 1.5px solid #ed093c; }

.orbit-button.danger:hover,
.orbit-button.delete:hover {
  background-color: #b2072d;
  border: 1.5px solid #b2072d; }

.orbit-button.green {
  background-color: white;
  color: #32b38b;
  border: 1.5px solid #32b38b; }

.orbit-button.green:hover {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1.5px solid white;
  color: white; }

.orbit-button.white {
  border: 1.5px solid white; }

.orbit-button:disabled {
  opacity: 0.6;
  background-color: #ddd7d1;
  border: 1.5px solid #ddd7d1;
  cursor: not-allowed; }

.orbit-button:disabled:hover {
  background-color: #ddd7d1;
  border: 1.5px solid #ddd7d1; }

/*******************
* Navbar           *
********************/
.orbit-navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #6d2acc;
  z-index: 4;
  height: 73px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; }

.navbar-title {
  margin: 0;
  margin-left: 25px;
  font-size: 1.5rem;
  color: white;
  font-weight: 400;
  font-family: 'Poppins', sans-serif; }

.navbar-user {
  margin: 0;
  margin-right: 12px;
  font-size: 1.3rem;
  color: white;
  font-weight: 300;
  font-family: 'Poppins', sans-serif; }

.navbar-icon {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  background-color: #ffb400; }

.navbar-brand {
  height: 55px; }

.no-photo {
  position: relative; }

.no-photo > i {
  position: absolute;
  top: 9px;
  left: -1px;
  right: 0;
  color: white;
  font-size: 1.5rem;
  text-align: center; }

@media only screen and (max-width: 720px) {
  .orbit-navbar {
    height: 60px; }
  .navbar-title {
    margin: 0;
    margin-left: 30px;
    font-size: 2rem;
    color: white;
    font-weight: 400;
    font-family: 'Poppins', sans-serif; }
  .navbar-user {
    display: none; }
  .navbar-brand {
    height: 38px; }
  .navbar-icon {
    height: 40px;
    width: 40px; } }

/*******************
* Footer           *
********************/
.orbit-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: white;
  align-items: center;
  padding: 75px 0px; }

.orbit-footer > * {
  width: 140px; }

.orbit-footer > img {
  height: 70px; }

.orbit-footer > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

@media only screen and (max-width: 680px) {
  .orbit-footer {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center; }
  .orbit-footer > img {
    margin: 17px 0px; }
  .orbit-footer > * {
    width: unset; } }

/*******************
* Call to Action   *
********************/
.index-header {
  height: 120px;
  padding-top: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1; }

.call-to-action {
  background: #947dc6;
  background: radial-gradient(circle, #491dacc9 0%, #6d2acc 45%);
  height: 850px;
  padding-top: 65px;
  position: relative;
  overflow: hidden; }

.call-to-action.final {
  background: #947dc6;
  background: radial-gradient(circle, #8635d7 0%, #6d2acc 45%);
  height: 725px;
  position: relative;
  overflow: hidden; }

.call-to-action.final > div.flex-container {
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  z-index: 10; }

.call-to-action > div.flex-container {
  position: absolute;
  z-index: 4; }

.call-to-action > div > div {
  max-width: 74.5vw;
  padding-top: 100px; }

.large-text {
  font-size: 5.5rem;
  line-height: 5.3rem;
  margin: 0;
  font-weight: 700;
  letter-spacing: 1px; }

.medium-text {
  font-size: 3rem;
  line-height: 3rem;
  margin: 0;
  font-weight: 600;
  margin-top: 35px; }

.call-to-action-final-text {
  text-align: center;
  font-size: 2.6rem;
  line-height: 3.3rem;
  font-weight: 600; }

img#laptop {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 37vw;
  z-index: 8; }

img#phone {
  position: absolute;
  bottom: 0;
  right: 32vw;
  height: 26vw;
  z-index: 9; }

.fill-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 155px;
  width: 100vw;
  background-image: url(/img/Fill-1.svg);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: cover; }

.fill-2 {
  position: absolute;
  bottom: -60px;
  left: -2px;
  height: 315px;
  width: 100vw;
  background-image: url(/img/Fill-2.svg);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: color-dodge;
  opacity: 0.4; }

.dots {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  background-image: url(/img/dots.svg);
  background-position: top left;
  background-repeat: repeat;
  mix-blend-mode: color-dodge;
  opacity: 0.4; }

/*******************
* What Can We Do   *
********************/
.what-can-we-do {
  font-size: 4rem;
  font-weight: 600;
  line-height: 4.5rem;
  padding-left: 10rem;
  padding-right: 6rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #868c95;
  text-align: left;
  min-width: 860px; }

.we-can {
  font-size: 1.4rem;
  line-height: 2.6rem;
  padding-right: 12rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #222;
  max-width: 190rem;
  text-align: left; }

.icon-text {
  font-size: 2rem; }

/*******************
* Getting Started  *
********************/
.getting-started-container {
  align-items: center;
  padding-top: 10rem; }

.getting-started-container > div {
  width: 85vw;
  margin-top: 3rem; }

.getting-started {
  font-size: 3.1rem;
  font-weight: 600;
  line-height: 3.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #868c95;
  text-align: center; }

.icon-features {
  flex-wrap: wrap; }

.icon-feature {
  max-width: 300px;
  margin-bottom: 30px; }

.icon-size {
  max-width: 94px;
  max-height: 94px; }

.icon-text {
  text-align: center; }

@media only screen and (max-width: 1440px) {
  .call-to-action > div > div {
    max-width: 55.5vw;
    padding-top: 100px; } }

@media only screen and (max-width: 1412px) {
  .icon-features {
    width: 63vw !important; }
  .we-can {
    padding-right: 4rem;
    max-width: 190rem; }
  .what-can-we-do {
    padding-right: 6rem;
    padding-left: 8rem;
    min-width: 770px; } }

@media only screen and (max-width: 1222px) {
  .what-can-we-do {
    min-width: 629px; } }

@media only screen and (max-width: 1030px) {
  .icon-features {
    width: 83vw !important; }
  .what-can-we-do {
    padding-left: 4rem;
    padding-right: 3rem;
    min-width: 560px; }
  .we-can {
    padding-right: 2.5rem; } }

@media only screen and (max-width: 952px) {
  .call-to-action > div > div {
    padding-top: 40px; }
  .call-to-action {
    height: 910px; } }

@media only screen and (max-width: 900px) {
  .call-to-action > div > div {
    padding-top: 100px; }
  .call-to-action {
    height: 1000px; }
  .hiring-process > div {
    flex-wrap: wrap; }
  .what-can-we-do {
    text-align: center;
    padding-left: 3rem;
    padding-right: 3rem; }
  .we-can {
    text-align: center;
    padding-left: 3rem;
    padding-right: 3rem; } }

@media only screen and (max-width: 830px) {
  .call-to-action.final > div > div {
    max-width: 100vw !important; } }

@media only screen and (max-width: 722px) {
  .call-to-action > div > div {
    max-width: 95vw; }
  .icon-features {
    width: 90vw !important; } }

@media only screen and (max-width: 695px) {
  .call-to-action.final {
    height: 925px; }
  .large-text {
    font-size: 4.2rem;
    line-height: 4.2rem; } }

@media only screen and (max-width: 600px) {
  .icon-features {
    width: 100%; }
  .call-to-action {
    height: 1000px; } }

@media only screen and (max-width: 515px) {
  .call-to-action {
    height: 1100px; } }

/*******************
* Revolutionize    *
********************/
.the-future-tablet {
  display: none; }

.the-future {
  padding-top: 150px;
  background-color: white; }

.the-future > div {
  margin-right: 4rem;
  margin-left: 4rem; }

.the-future > div > h1 {
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 600;
  color: #868c95; }

.the-future > div > p {
  font-size: 1.1rem;
  line-height: 2rem;
  padding-top: 50px;
  max-width: 44vw;
  color: #222; }

.revolutionize-container {
  background-image: url(/img/Revolutionize-the-way-you-find.png);
  background-position: center;
  background-repeat: none;
  background-size: cover;
  height: 525px;
  position: relative; }

.revolutionize-container.alt {
  background-image: url(/img/Revolutionize-the-way-you-find2.png) !important;
  background-color: #d3dde4 !important;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 0px; }

.revolutionize-container > div {
  position: absolute;
  right: 100px;
  top: 0px;
  height: 525px; }

.revolutionize-container > div > p {
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 700;
  margin-bottom: 40px;
  color: white;
  text-align: center;
  width: 50vw; }

@media only screen and (max-width: 675px) {
  .revolutionize-container {
    background: #ddd8d2 !important;
    height: unset;
    padding-top: 30px;
    padding-bottom: 50px; }
  .revolutionize-container > div {
    position: initial;
    height: unset;
    padding-top: 10px; }
  .revolutionize-container > div > p {
    width: 80vw; } }

#UI {
  -webkit-box-shadow: -6px -6px 18px -8px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: -6px -6px 18px -8px rgba(0, 0, 0, 0.39);
  box-shadow: -6px -6px 18px -8px rgba(0, 0, 0, 0.39);
  border-top-left-radius: 15px;
  max-width: 820px;
  margin-top: -50px; }

@media only screen and (max-width: 1500px) {
  #UI {
    align-self: center;
    max-width: 630px; }
  .the-future > div > p {
    padding-bottom: 100px; } }

@media only screen and (max-width: 1100px) {
  .the-future {
    display: none; }
  .the-future > div > p {
    max-width: 90vw;
    align-self: center; }
  .the-future > div > h1 {
    padding-top: 70px;
    align-self: center;
    padding-bottom: 20px; }
  .the-future-tablet {
    display: initial;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center; }
  img#UI {
    max-width: unset;
    width: 65%;
    align-self: center;
    margin-top: 40px;
    margin-bottom: 50px; } }

@media only screen and (max-width: 900px) {
  img#UI {
    width: 100%; } }

/*******************
* Interview        *
********************/
.interview-container {
  position: relative;
  min-height: 50vh; }

.interview-header {
  padding-bottom: 125px;
  padding-top: 110px; }

.interview-answer-progress {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 200px;
  padding-top: 75px;
  background-color: #491dac;
  margin-bottom: 150px; }
  .interview-answer-progress .interview-answer-inner {
    display: flex;
    flex-direction: column;
    position: relative; }
    .interview-answer-progress .interview-answer-inner .label-text {
      font-size: 1.2rem;
      line-height: 3rem;
      color: white;
      font-weight: 500;
      margin-top: 10px;
      max-width: 70vw; }
    .interview-answer-progress .interview-answer-inner .answer-progress {
      display: flex;
      flex-direction: row;
      position: relative;
      margin-top: 5px;
      margin-bottom: 20px;
      justify-content: center; }
      .interview-answer-progress .interview-answer-inner .answer-progress .item {
        margin-right: 5px;
        margin-left: 5px;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        cursor: pointer; }
        .interview-answer-progress .interview-answer-inner .answer-progress .item i {
          position: absolute;
          margin-left: 3px;
          margin-top: 3px; }
      .interview-answer-progress .interview-answer-inner .answer-progress .inactive {
        border: 1.5px solid #ddd7d1;
        background-color: #ddd7d1; }
      .interview-answer-progress .interview-answer-inner .answer-progress .active {
        border: 1.5px solid #32b38b;
        background-color: white;
        color: #32b38b; }
      .interview-answer-progress .interview-answer-inner .answer-progress .completed {
        border: 1.5px solid #32b38b;
        background-color: #32b38b;
        color: white; }

.interview-title {
  color: #868c95;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 4.9rem;
  margin: 0; }

.interview-company {
  color: #868c95;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin: 0;
  margin-top: 15px; }

.orbit-interview-card {
  background-color: white;
  position: relative;
  height: 500px;
  width: 1000px;
  -webkit-box-shadow: 5px 5px 21px -2px rgba(0, 0, 0, 0.07);
  box-shadow: 5px 5px 21px -2px rgba(0, 0, 0, 0.07); }

.orbit-interview-card-right {
  background-color: #f9fafd;
  position: absolute;
  right: 0;
  top: 0;
  height: 500px;
  width: 580px;
  transition: all 0.6s; }

.orbit-interview-card-right.dragged-over {
  background-color: #adbae4; }

.orbit-interview-step {
  color: #6c727c;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;
  margin-top: 25px;
  transition: all 0.3s; }

.orbit-interview-step > img {
  margin-right: 25px;
  height: 30px;
  width: 30px; }

.orbit-interview-step > i {
  margin-right: 25px;
  border: 1.5px solid #868c95;
  height: 30px;
  width: 30px;
  border-radius: 50%; }

.orbit-interview-step.active {
  color: #32b38b;
  font-weight: 500; }

.orbit-interview-step.active > i {
  border-color: #32b38b; }

/*******************
* Interview Resume *
********************/
.orbit-dropbox-inner {
  background-color: white;
  padding: 25px 60px 25px 60px;
  transition: all 0.4s; }

.orbit-dropbox-inner.dragged-over {
  background-color: #adbae4; }

.orbit-dropbox-inner.dragged-over > img {
  filter: brightness(0) invert(1); }

.orbit-dropbox-inner.dragged-over > p {
  color: white; }

.orbit-dropbox-inner.dragged-over > input + label {
  color: white;
  border-color: white;
  background-color: transparent; }

.orbit-dropbox-inner > img {
  margin: 0;
  margin-right: 20px; }

.orbit-dropbox-inner > p {
  margin: 0;
  margin-right: 20px;
  font-size: 0.9rem;
  font-weight: 400;
  color: #868c95; }

.orbit-dropbox-inner > input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.orbit-dropbox-inner > input + label {
  margin: 0;
  font-size: 1rem;
  cursor: pointer; }

.orbit-dropbox-inner > input:focus + label,
.orbit-dropbox-inner > input + label:hover {
  background-color: #32b38b !important; }

.orbit-interview-card-left.flex-vertical > button {
  position: absolute;
  bottom: 20px;
  left: 25px;
  width: 350px;
  font-size: 1.1rem; }

.step-1 {
  margin-top: 40px; }

.step {
  left: 0;
  display: flex;
  right: 0;
  align-items: center;
  justify-content: center;
  top: -100px; }

@media only screen and (max-width: 1200px) {
  .orbit-interview-card {
    width: 846px; }
  .orbit-interview-card-right {
    width: 503px; }
  .orbit-interview-card-left.flex-vertical > button {
    width: 273px; } }

@media only screen and (max-width: 855px) {
  .orbit-interview-card {
    width: 97vw;
    height: fit-content; }
  .orbit-interview-card-right {
    width: 100%;
    height: 300px;
    position: initial; }
  .orbit-interview-card-left.flex-vertical > button {
    width: 87vw;
    position: initial;
    margin-top: 50px;
    align-self: center; }
  .orbit-dropbox-inner {
    width: 90vw; } }

@media only screen and (max-width: 445px) {
  .orbit-dropbox-inner {
    justify-content: center; }
  .orbit-dropbox-inner > img {
    display: none; }
  .orbit-dropbox-inner > p {
    display: none; } }

.upload-row {
  align-items: center;
  margin-top: 15px;
  background-color: transparent; }

.upload-row > p {
  color: #868c95;
  margin-bottom: 0; }

.upload-row > p.status-text {
  margin-left: 2px;
  margin-right: 15px;
  font-size: 0.8rem; }

.upload-row > p.file-name {
  margin-right: 15px;
  font-size: 0.8rem; }

.upload-row > .upload-bar {
  transition: all 0.6s;
  width: var(--progress);
  height: 10px;
  border-radius: 20px;
  background-color: #32b38b; }

.upload-row > p.active {
  color: #32b38b; }

/********************
* Interview Confirm *
*********************/
.confirm-details {
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: flex-start; }

.error-list {
  margin-bottom: 5px; }

.error > i {
  margin-right: 15px;
  color: #ed093c; }

.confirm-details > p.explanation {
  margin-top: 50px;
  color: #868c95;
  font-size: 0.9rem;
  margin-bottom: 25px; }

.confirm-details input {
  border: 1px solid rgba(32, 32, 32, 0.05);
  background-color: white;
  padding: 10px 20px 10px 20px; }

.confirm-details input:first-child {
  margin-right: 25px; }

.personal-details {
  margin-bottom: 25px; }

.personal-details > div {
  margin-bottom: 15px; }

.personal-details > div > input {
  max-width: 260px; }

.skills-container {
  margin-top: 25px;
  max-width: 525px;
  margin-left: 0.15rem; }

.skills-container > h2 {
  font-size: 1.4rem;
  color: black;
  margin: 0px 0px 8px 0px;
  font-weight: 400; }

.skills-container > p {
  color: #868c95;
  font-size: 0.9rem;
  line-height: 1rem;
  margin: 0px 0px 18px 0px;
  max-width: 500px; }

.skills-container button {
  font-size: 1.6rem;
  padding: 9px 45px 9px 45px;
  margin: 0;
  line-height: 19px; }

.add-skill {
  align-items: center; }

.add-skill > input {
  width: 100%; }

.skill-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
  max-width: 504px; }

.skill-list > .skill {
  min-width: 100px;
  min-height: 35px;
  border-radius: 17.5px;
  margin: 10px 10px 0px 0px;
  padding: 5px 20px;
  border: 1.5px solid #32b38b;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #32b38b;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.3s; }

.skill-list > .skill:hover {
  background-color: #d7f4eb; }

.begin-interview-text {
  max-width: 255px;
  margin-left: 95px;
  margin-right: 60px;
  margin-top: 10px; }

.begin-interview-text > p {
  font-size: 1rem; }

.begin-interview-text > p:first-child {
  font-size: 1rem; }

/*********************
* Interview Question *
**********************/
.question-card.recording {
  background-color: black; }

.recording-card {
  position: relative;
  transition: all 0.6s;
  padding: 20px;
  box-sizing: border-box; }
  .recording-card .begin-recording {
    padding-left: 100px;
    padding-right: 100px;
    font-size: 1.3rem;
    z-index: 1; }
  .recording-card .record-container.show {
    z-index: 2; }

.question-card {
  width: 950px;
  height: 525px;
  border-radius: 6px;
  padding: 20px;
  background-color: #491dac;
  position: relative;
  transition: all 0.6s; }
  .question-card .question-number {
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: white;
    font-weight: 300;
    text-align: center; }
  .question-card .question-number.finished {
    font-size: 1.15rem;
    z-index: 1; }
  .question-card .question-text {
    font-size: 2.7rem;
    line-height: 3rem;
    color: white;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 50px;
    max-width: 70vw; }
  .question-card .question-text.finished {
    font-size: 2rem;
    line-height: 2.5rem;
    z-index: 1; }
  .question-card .record-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.6s;
    z-index: -1; }
  .question-card .begin-recording {
    padding-left: 100px;
    padding-right: 100px;
    font-size: 1.3rem;
    z-index: 1; }
  .question-card .record-container.show {
    z-index: 2; }
  .question-card #planet {
    position: absolute;
    z-index: 0;
    top: 20px;
    left: 20px;
    opacity: 0.6; }
  .question-card #antenna {
    position: absolute;
    z-index: 0;
    bottom: 0px;
    right: 50px;
    opacity: 0.6; }

.button-holder {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

@media only screen and (max-width: 950px) {
  .question-card {
    width: 95vw;
    height: unset; }
    .question-card .begin-recording {
      width: 87vw;
      font-size: 1.8rem; }
  .question-card.recording {
    background-color: transparent; } }

.login-container {
  min-height: 100vh; }

.left-img {
  max-width: 35vw;
  min-width: 35vw;
  background: url(/img/login.png);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-size: 120%;
  background-repeat: no-repeat; }

.left-img > a {
  display: flex;
  justify-content: center; }

.left-img > a > img {
  margin-top: 50px;
  width: 30%; }

.right-side {
  max-width: 64vw;
  min-width: 64vw;
  background-color: white;
  align-items: center; }

@media only screen and (max-width: 1380px) {
  .left-img {
    background-size: 149%;
    background-position: -50px;
    background-position-y: top; } }

@media only screen and (max-width: 1145px) {
  .left-img {
    max-width: 13vw;
    min-width: 13vw;
    background-size: cover;
    background-position: 40%; }
  .right-side {
    max-width: 87vw;
    min-width: 87vw; } }

.right-side > h1 {
  font-weight: 500;
  color: #6d2acc;
  text-align: center;
  padding: 0px 25px;
  max-width: 720px; }

.right-side > form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.right-side > form > div {
  display: flex;
  flex-direction: column;
  color: #ed093c; }

.login-input {
  border: 1.5px solid #ddd7d1;
  padding: 15px;
  margin: 10px 0px;
  width: 700px;
  transition: all 0.4s;
  font-size: 1rem; }

.login-input.invalid {
  border: 1.5px solid #ed093c; }

.right-side > form > button {
  margin-top: 35px;
  margin-bottom: 15px; }

label.checkbox {
  align-self: flex-start;
  display: inline-flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  flex-direction: row; }

label.checkbox > span {
  color: #60666e;
  padding: 0.5rem 0.25rem;
  margin-left: 8px; }
  label.checkbox > span a:visited {
    color: #491dac; }

label.checkbox > input {
  height: 25px;
  width: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1.5px solid #ddd7d1;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: white;
  cursor: pointer; }

label.checkbox > input:checked + span::before {
  content: '\2713';
  display: block;
  text-align: center;
  color: #868c95;
  position: absolute;
  left: 0.37rem;
  top: 0.6rem; }

label.checkbox > input:active {
  border: 2px solid #34495e; }

.existing-account:visited {
  color: #32b38b; }

@media only screen and (max-width: 820px) {
  .left-img {
    max-width: 0vw;
    min-width: 0vw;
    background-size: cover;
    background-position: 40%; }
  .right-side {
    max-width: 100vw;
    min-width: 100vw; }
  .login-input {
    width: 90vw; }
  .working-for-you {
    text-align: center;
    line-height: 3.4rem; } }

.call-to-action-final-text {
  font-size: 2.5rem;
  line-height: 2.8rem; }

.getting-started {
  font-size: 2.7rem;
  line-height: 2.9rem; }

@media only screen and (max-width: 550px) {
  .revolutionize-container > div > p {
    font-size: 2.2rem;
    line-height: 2.7rem; }
  .the-future > div > h1 {
    font-size: 2.3rem;
    line-height: 2.6rem; }
  .the-future > div > p {
    line-height: 2rem;
    max-width: 100vw;
    min-width: 80vw; }
  .what-can-we-do {
    min-width: unset;
    max-width: 92vw;
    padding: 0; }
  .we-can {
    min-width: unset;
    max-width: 92vw;
    padding: 0; }
  #root > div:nth-child(2) > div.flex-container > div > div,
  #root > div.flex-vertical.call-to-action.final > div.flex-container > div > div {
    flex-direction: column; }
  .call-to-action-final-text {
    font-size: 1.7rem;
    line-height: 2rem; }
  .getting-started {
    font-size: 2.2rem;
    line-height: 2.4rem;
    padding: 0 10px; }
  .getting-started-container.bg-white > div > div > p {
    font-size: 1.2rem;
    line-height: 1.4rem; }
  .what-can-we-do {
    font-size: 2.5rem;
    line-height: 2.7rem; }
  .we-can {
    font-size: 1.4rem;
    line-height: 1.6rem; }
  .getting-started-container.bg-white {
    margin: 0; } }

@media only screen and (max-width: 325px) {
  .flex-container {
    margin: 0 0px; }
  .orbit-button {
    padding: 5px 20px;
    min-width: unset;
    max-width: unset;
    width: 90%; }
  #root > div:nth-child(2) > div.flex-container > div > div > .orbit-button {
    width: 100%; }
  #root > div:nth-child(2) > .flex-container > div > .large-text {
    font-size: 3.6rem;
    line-height: 3.7rem; }
  #root > div:nth-child(2) > .flex-container > div > .medium-text {
    font-size: 2.6rem;
    line-height: 3rem; } }

.orbit-modal,
.orbit-modal-container {
  transition: all 0.4s; }

.orbit-modal-bg {
  transition: opacity 0.4s; }

.orbit-modal-bg {
  position: fixed;
  background: rgba(0, 0, 0, 0.25);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1065; }

.orbit-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 10066;
  background: transparent;
  bottom: unset;
  position: fixed;
  align-self: center;
  top: 1rem;
  left: 0;
  right: 0;
  bottom: 0; }

.orbit-modal {
  position: relative;
  max-width: 95vw;
  max-height: 95vh;
  overflow-y: auto;
  background-color: white;
  border-radius: 8px;
  z-index: 1067;
  padding: 2rem 2rem 0.5rem 2rem;
  min-width: 18rem; }

.orbit-modal > .close {
  position: absolute;
  top: 10px;
  right: 15px; }

.orbit-modal-header {
  margin-bottom: 20px; }
  .orbit-modal-header h2 {
    color: #491dac;
    font-size: 1.5rem;
    line-height: 1.8rem; }

.orbit-modal-body {
  min-width: 340px; }

.orbit-modal-body > form {
  width: 100%; }

.orbit-modal-footer {
  margin-top: 30px; }
  .orbit-modal-footer button {
    padding: 8px 30px 8px 30px;
    font-size: 1.2rem;
    line-height: 1rem;
    margin: 0;
    margin-bottom: 15px;
    width: unset; }

.orbit-modal.closed,
.orbit-modal-bg.closed,
.orbit-modal-container.closed {
  z-index: -5 !important;
  opacity: 0; }

.orbit-modal.open,
.orbit-modal-bg.open,
.orbit-modal-container.open {
  opacity: 1; }

.orbit-modal.closed,
.orbit-modal-container.closed {
  transform: translateY(-100px); }

.orbit-modal.open,
.orbit-modal-container.open {
  transform: translateY(0px); }

.interview-container.embed {
  position: unset;
  min-height: unset; }

.interview-container.embed .step {
  top: 0;
  bottom: 0; }

.fg-white {
  color: white; }

.fg-black {
  color: black; }

.fg-red {
  color: #ed093c; }

.fg-orange {
  color: #fa5d35; }

.fg-yellow {
  color: #ffb500; }

.fg-green {
  color: #32b38b; }

.fg-blue {
  color: #5772e5; }

.fg-purple {
  color: #6d2acc; }

.fg-purple-darker {
  color: #491dac; }

.fg-purple-lighter {
  color: #8635d7; }

.fg-grey {
  color: #868c95; }

.fg-light-grey {
  color: #ddd7d1; }

.fg-transparent {
  color: transparent; }

.bg-white {
  background-color: white; }

.bg-black {
  background-color: black; }

.bg-red {
  background-color: #ed093c; }

.bg-orange {
  background-color: #fa5d35; }

.bg-yellow {
  background-color: #ffb500; }

.bg-green {
  background-color: #32b38b; }

.bg-blue {
  background-color: #5772e5; }

.bg-purple {
  background-color: #6d2acc; }

.bg-purple-darker {
  background-color: #491dac; }

.bg-purple-lighter {
  background-color: #8635d7; }

.bg-grey {
  background-color: #868c95; }

.bg-light-grey {
  background-color: #ddd7d1; }

.bg-transparent {
  background-color: transparent; }

.blog-content {
  padding-bottom: 115px; }

.blog-content > div.container {
  z-index: 2; }

.privacy-text {
  max-width: 50vw;
  margin: 2rem 0;
  text-align: center; }

/* set the overriding variables */
/* override the !default vars with the values we set above */
/*!
 * Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 667px) {
    .container {
      max-width: 667px; } }
  @media (min-width: 980px) {
    .container {
      max-width: 980px; } }
  @media (min-width: 1300px) {
    .container {
      max-width: 1300px; } }
  @media (min-width: 1624px) {
    .container {
      max-width: 1624px; } }
  @media (min-width: 2240px) {
    .container {
      max-width: 2240px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 667px) {
  .container-sm {
    max-width: 667px; } }

@media (min-width: 980px) {
  .container-sm, .container-md {
    max-width: 980px; } }

@media (min-width: 1300px) {
  .container-sm, .container-md, .container-lg {
    max-width: 1300px; } }

@media (min-width: 1624px) {
  .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1624px; } }

@media (min-width: 2240px) {
  .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 2240px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*='col-'] {
    padding-right: 0;
    padding-left: 0; }

.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

@media (min-width: 667px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 980px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1300px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1624px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 2240px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 667px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 980px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1300px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1624px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 2240px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 667px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 980px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1300px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1624px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 2240px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

@media (min-width: 667px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 980px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1300px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1624px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 2240px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

#root {
  background: #f8f9ff; }

.dash-container {
  display: flex;
  flex-direction: row;
  flex: 1; }

.main-content {
  flex: 1; }

.user-dropdown {
  margin-right: 0.6rem; }

.orbit-sidebar {
  z-index: 3;
  background: white;
  min-height: 100vh;
  max-width: 270px;
  min-width: 250px;
  overflow: hidden;
  transition: all 0.3s; }

.empty-notice {
  width: 100%; }

.orbit-sidebar.closed {
  max-width: 60px;
  min-width: 0px; }

.sidebar-header {
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center; }

.sidebar-header > img.toggle-sidenav {
  height: 19px;
  width: 25px;
  margin: 15px 2.5rem 15px 1rem;
  cursor: pointer; }

.sidebar-header > img {
  height: 41px; }

.sidebar-links {
  margin-top: 60px;
  margin-bottom: 90px; }

.orbit-dash-link {
  margin: 0px;
  padding: 18px 0px 18px 1rem; }

.orbit-dash-link.active {
  background-color: #f8f9ff; }

.orbit-dash-link > a > img {
  margin-right: 1rem;
  height: 23px;
  width: 23px; }

.nav-link-text {
  color: #868c95;
  transition: all 0.3s;
  font-size: 1rem;
  line-height: 1rem; }

.nav-link-text:hover {
  color: #8635d7; }

.orbit-dash-link.active > a > img {
  filter: invert(12%) sepia(97%) saturate(8310%) hue-rotate(258deg) brightness(75%) contrast(98%); }

.orbit-dash-link.active .nav-link-text {
  color: #491dac; }

.sidebar-account > h5 {
  margin-left: 1rem;
  font-size: 1.2rem;
  line-height: 1.3rem;
  margin-bottom: 1.5rem;
  color: #491dac; }

.horizontal-scroll-row {
  max-width: 90vw;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow-x: scroll; }

.sidebar-account .plan {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 50px;
  padding-left: 1rem; }

.sidebar-account .plan > img {
  margin-right: 1rem; }

.sidebar-account .plan > p {
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  color: #373940; }

div.card-body > div.bootstrap-tagsinput > span > span.tag.badge.mr-1 {
  background-color: #c1e1dc;
  border-radius: 6px;
  box-sizing: border-box;
  color: #212529;
  display: inline-block;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 10.56px;
  font-weight: 600;
  line-height: 10.56px;
  margin-left: 4px;
  overflow-wrap: break-word;
  padding-bottom: 5.6px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 5.6px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s; }

div.card-body > div.bootstrap-tagsinput > span > span.tag.badge.mr-1:hover {
  padding-right: 30px; }

div.card-body > div.bootstrap-tagsinput > span > .badge:hover a {
  transition: all 0.3s;
  opacity: 0;
  color: #222; }

div.card-body > div.bootstrap-tagsinput > span > .badge:hover a {
  opacity: 1;
  color: #222; }

.resend-email-card {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 240px;
  border-radius: 8px;
  border: #ddd7d1 1.5px solid;
  padding: 1.5rem 0rem;
  margin: 0px 1rem; }

.resend-email-card > h2 {
  color: #6d2acc;
  line-height: 1.8rem;
  font-size: 1.4rem; }

.resend-email-card > h5 {
  font-weight: 300;
  color: #373940;
  font-size: 1rem; }

.resend-email-card > button {
  font-size: 1rem;
  line-height: 1.8rem;
  margin: 0rem;
  padding: 3px 18px; }

.dash-content {
  margin-left: 2rem; }

.activity-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 2rem; }

.activity-title {
  font-size: 1.1rem;
  font-weight: 400;
  margin: 60px 0;
  color: #491dac; }

.button-container {
  align-items: center; }

.button-container > button {
  padding: 6px 10px;
  font-size: 1rem; }

.button-container > button > img {
  margin-right: 9px;
  height: 25px; }

.feed-container {
  margin-right: 2.5rem; }

.activity-post {
  padding: 1rem;
  background: white;
  margin-bottom: 1rem;
  border-radius: 4px;
  -webkit-box-shadow: 3px 4px 18px -8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 4px 18px -8px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 4px 18px -8px rgba(0, 0, 0, 0.2); }

.view-profile {
  font-size: 1rem; }

.orbit-button.green.view-profile {
  font-size: 1rem;
  padding: 8px 18px; }

.divider {
  border-bottom: 2px solid rgba(0, 0, 0, 0.04);
  margin: 20px -16px; }

.post-inner {
  background-color: #f9fafd;
  padding: 2rem 2rem 0rem 2rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  flex: 1; }

.post-inner > .left-container {
  flex: 1; }

.post-inner.progress-bar-container {
  padding: 1rem 0rem 2.5rem 0rem;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  flex: 1; }

.play-button-container {
  height: 100%; }

.post-play {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  background-color: #32b38b;
  border: 5px solid #a9ddd1;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.4s;
  margin: 0rem 2.7rem 0rem 0rem; }

.post-inner-container {
  background-color: #f9fafd;
  margin: 2rem 1rem 2rem 1rem; }

.post-play:hover {
  background-color: #21775d;
  border: 5px solid #72c7b4; }

.edit-avatar-container {
  display: flex;
  justify-content: center;
  align-items: center; }

.edit-avatar {
  height: 70px;
  width: 70px; }

.post-user {
  font-size: 1rem;
  color: #868c95;
  font-weight: 500;
  margin: 0; }

.post-user:visited {
  color: #868c95; }

.post-date {
  font-size: 0.8rem;
  color: #868c95;
  font-weight: 300;
  margin: 0; }

.post-title {
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: #373940;
  font-weight: 500;
  margin: 0; }

.post-subtitle {
  font-size: 0.75rem;
  line-height: 2rem;
  color: #373940;
  font-weight: 400;
  margin: 0; }

.post-subtitle > span {
  color: #491dac;
  font-weight: 600; }

.progress-container {
  width: 100%; }

.progress-container > div {
  position: relative; }

.progress-container .progress-bar {
  width: var(--progress);
  height: 8px;
  background-color: #ffb500;
  border-radius: 15px;
  transition: all 0.4s;
  position: absolute;
  z-index: 2; }

.progress-container .progress-track {
  position: absolute;
  left: 0px;
  right: 0px;
  height: 8px;
  background-color: white;
  border-radius: 15px; }

.like-and-comment.main {
  margin-left: 1rem; }

.like-and-comment .like {
  margin-right: 1.8rem; }

.like-and-comment > a > img {
  margin-right: 7px; }

.like-and-comment > a > span {
  font-size: 0.75rem; }

.like-and-comment .comment > img {
  margin-right: 7px; }

.like-and-comment .comment > span {
  font-size: 0.75rem; }

.like-and-comment .comment:hover {
  cursor: default; }

.post-avatar img {
  border-radius: 50%;
  object-fit: cover;
  height: 100%;
  overflow: hidden;
  height: 54px;
  width: 54px;
  margin-left: 1rem;
  margin-right: 0.65rem; }

.post-comment {
  margin: 45px 0px; }

.post-comment-avatar > img {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  margin: 0rem 1rem 0rem 0rem; }

.post-comment-body {
  padding-top: 15px; }
  .post-comment-body .author {
    font-size: 1.05rem;
    line-height: 1.05rem;
    color: #868c95;
    font-weight: 500; }
  .post-comment-body .text {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #868c95;
    font-weight: 400; }

.item-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap; }

.item-list > * {
  margin: 0.56rem; }

.placement-card {
  background: white;
  padding: 15px 25px;
  border-radius: 6px;
  -webkit-box-shadow: 3px 4px 18px -8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 4px 18px -8px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 4px 18px -8px rgba(0, 0, 0, 0.2);
  margin-bottom: 1.56rem; }

.placement-dimentions {
  min-width: 320px;
  max-width: 395px; }

.interview-dimentions {
  min-width: 320px;
  max-width: 368px; }

.placement-total {
  size: 3; }

.placement-card.scroll-body {
  padding: 15px 0px 0px 0px; }

.placement-card.scroll-body > .card-header {
  padding: 5px 25px; }

.placement-card.scroll-body > .card-body {
  padding: 0px 25px 15px 25px; }

.card-body.vertical-scroll {
  overflow-x: hidden; }

.placement-card.flex-vertical {
  cursor: default; }

.placement-card-header {
  margin-bottom: 3px;
  justify-content: space-between; }
  .placement-card-header .top-right .link {
    font-weight: 500;
    color: #6d2acc;
    cursor: pointer;
    text-decoration: underline; }

.placement-card-header > h5 {
  color: #868c95; }

.card-divider {
  border-bottom: 2px solid rgba(0, 0, 0, 0.04);
  margin: 10px -25px; }

.placement-card-header > li > button {
  background-color: white;
  box-shadow: none;
  color: #32b38b; }

.placement-card-body {
  margin-top: 5px;
  margin-bottom: 5px; }

.placement-card-body.flex-horizontal > .play-button-container > div {
  height: 60px;
  width: 60px; }

.placement-card-body.flex-horizontal > div:nth-child(2) > h4 > div {
  color: #868c95;
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-weight: 500; }

.placement-card-body.flex-horizontal.description-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 0.8rem; }
  .placement-card-body.flex-horizontal.description-row .total-for-row {
    color: #6d2acc;
    font-weight: 600;
    width: 25px; }
  .placement-card-body.flex-horizontal.description-row p {
    font-weight: 500;
    font-size: 1rem; }
  .placement-card-body.flex-horizontal.description-row p:first-of-type {
    color: #373940;
    margin-left: 0.8rem;
    width: 200px; }
  .placement-card-body.flex-horizontal.description-row p:last-of-type {
    color: #6d2acc;
    text-decoration: underline;
    cursor: pointer;
    right: 0;
    position: absolute; }

.placement-card-footer p {
  font-weight: 500;
  font-size: 0.85rem;
  color: #868c95; }
  .placement-card-footer p .employee-name {
    color: #6d2acc;
    text-decoration: underline;
    cursor: pointer; }

.placement-card-footer button {
  float: right; }

.section-header {
  margin: 40px 0px 20px;
  color: #491dac; }

.section-header-link {
  align-items: center; }
  .section-header-link .link {
    font-size: 1rem;
    font-weight: 500;
    color: cornflowerblue;
    cursor: pointer;
    text-decoration: underline; }

.board-popular-items {
  border-radius: 4px;
  margin: 1rem 0;
  -webkit-box-shadow: 3px 4px 18px -8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 4px 18px -8px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 4px 18px -8px rgba(0, 0, 0, 0.2);
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem; }
  .board-popular-items .board-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    justify-content: space-between;
    height: 70px; }
    .board-popular-items .board-item .item-number {
      width: 30px;
      padding: 0.1rem 0.5rem;
      color: white;
      text-align: center; }
    .board-popular-items .board-item .picture {
      min-height: 50px;
      min-width: 50px;
      max-height: 50px;
      max-width: 50px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      background-color: #ffb500;
      margin-left: 1rem; }
    .board-popular-items .board-item .title {
      font-size: 1rem;
      font-weight: 500;
      color: black;
      margin-left: 1rem;
      width: 200px; }
    .board-popular-items .board-item .subtitle {
      margin-left: 1rem;
      width: 175px; }
    .board-popular-items .board-item .rated-item {
      width: 70px;
      padding: 0.1rem 0.5rem;
      color: white;
      text-align: center;
      margin-left: 1rem; }
    .board-popular-items .board-item .yellow {
      background-color: #ffb500; }
    .board-popular-items .board-item .grey {
      background-color: grey; }
  .board-popular-items .board-footer {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: flex-end; }
    .board-popular-items .board-footer .board-link {
      font-weight: 500;
      color: cornflowerblue;
      cursor: pointer;
      text-decoration: underline;
      height: 40px; }

.horizontal-scroll-row {
  max-width: 90vw;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 60vh;
  margin-bottom: 1rem; }
  @media screen and (min-width: 1200px) {
    .horizontal-scroll-row {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      overflow-x: scroll; }
      .horizontal-scroll-row::-webkit-scrollbar {
        width: 1em; }
      .horizontal-scroll-row::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 8px; }
      .horizontal-scroll-row::-webkit-scrollbar-thumb {
        background-color: #6b45c5;
        border-radius: 8px; } }

.horizontal-scroll-row > div {
  margin-bottom: 1rem; }

.candidate-drag-container > div > div > div {
  padding-left: 0; }

.list-container {
  width: 100%; }

.candidate-list {
  min-height: 730px;
  max-height: 730px;
  overflow-y: auto;
  margin-bottom: 1rem; }

.candidate-card {
  flex-direction: column;
  padding: 10px 17px; }

.contact-card-margin {
  margin-bottom: 1rem; }

.placement-card.flex-vertical.candidate-card {
  margin-bottom: 1rem;
  cursor: grab; }

.candidate-card-header {
  align-items: center; }
  .candidate-card-header div.avatar {
    margin-right: 0.5rem; }

.skill-score-bar {
  margin: 10px 0; }

.skill-score-text {
  color: #373940;
  font-size: 0.75rem;
  font-weight: 400; }

.candidate-body > small {
  color: #373940;
  font-weight: 500;
  font-size: 0.85rem; }

.candidate-card-header > div:nth-child(2) {
  color: #868c95;
  font-weight: 500;
  font-size: 1.05rem; }

@media screen and (max-width: 1310px) {
  .item-list > * {
    margin: 0.35rem; }
  .placement-card {
    min-width: 260px; }
  .item-list {
    flex-wrap: wrap; }
  .horizontal-scroll-row {
    max-width: 90vw;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow-x: scroll; }
  .board-popular-items .board-item .title {
    width: 170px; }
  .board-popular-items .board-item .subtitle {
    width: 125px; } }

@media only screen and (max-width: 800px) {
  .flex-vertical.orbit-sidebar.closed {
    margin-left: -270px; }
  .flex-vertical.orbit-sidebar.open {
    margin-left: 0; }
  .item-list > * {
    margin-right: 0; }
  .placement-card {
    max-width: 100%; }
  .orbit-sidebar > .flex-horizontal.sidebar-header {
    justify-content: center;
    align-items: center; }
  .orbit-sidebar > .flex-horizontal.sidebar-header > img.orbit-icon {
    display: none; }
  .body-container > div > div.activity-header > div {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end; }
  .horizontal-scroll-row {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 95vw;
    margin: 0; }
  .horizontal-scroll-row > div {
    flex: 1 1 100%; }
  .candidate-list {
    min-height: unset; } }

@media only screen and (max-width: 650px) {
  .orbit-sidebar {
    max-width: unset;
    min-width: unset; }
  .nav-link-text {
    display: none; }
  .sidebar-account .plan > p {
    display: none; }
  .resend-email-card {
    display: none; }
  .sidebar-account > h5 {
    display: none; }
  .sidebar-header > img {
    height: 20px; } }

@media only screen and (max-width: 600px) {
  .dash-content {
    margin-left: 0; }
  div.flex-horizontal.flex-center.justify-between.post-inner-container {
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  div.flex-horizontal.flex-center.justify-between.post-inner-container > div.play-button-container > div {
    margin: 0 0 25px 0; }
  div.activity-header {
    flex-direction: column;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px; }
  .feed-container {
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px; }
  .activity-title {
    margin-bottom: 15px; }
  .button-container {
    margin-bottom: 25px;
    justify-content: center;
    align-items: center; }
  .body-container > div > div.activity-header > div {
    align-items: center; }
  .button-container > button {
    text-align: right;
    width: 100%; }
  div.post-header > div {
    flex-wrap: wrap; }
  .post-play {
    margin-right: 1.5rem; }
  .body-container > div > div.feed-container > div > div > div > div {
    padding-right: 0; }
  .board-popular-items .board-item .title {
    width: 60px; }
  .board-popular-items .board-item .subtitle {
    width: 65px; } }

.flex-horizontal.candidate-card-header {
  overflow-x: hidden; }

div.candidate-list div.candidate-card {
  width: unset;
  min-width: unset;
  flex-grow: 1; }

@media screen and (max-width: 530px) {
  .candidate-list {
    width: 95vw; } }

@keyframes expandSlackStroke {
  35% {
    stroke-dasharray: 0, 85; }
  40% {
    stroke-dasharray: 81, 81; }
  95% {
    stroke-dasharray: 81, 81; }
  100% {
    stroke-dasharray: 0, 85; } }

@keyframes rotateSlackIcon {
  30% {
    transform: rotate(345deg); }
  100% {
    transform: rotate(345deg); } }

@keyframes shrinkSlackIcon {
  10% {
    transform: scale(1); }
  15% {
    transform: scale(0.5); }
  20% {
    transform: scale(1); } }

.slack-loading {
  max-width: fit-content;
  text-align: center; }
  .slack-loading .rotate {
    transform: rotate(-15deg);
    animation: rotateSlackIcon 3.5s infinite; }
  .slack-loading > .shrink {
    animation: shrinkSlackIcon 3.5s infinite cubic-bezier(0.79, 0.14, 0.15, 0.86); }
  .slack-loading svg {
    height: 1em;
    width: 1em;
    font-size: 8em;
    transform: rotate(-15deg);
    margin-bottom: 1.3rem; }
    .slack-loading svg line {
      stroke-width: 18;
      stroke-linecap: round;
      stroke-miterlimit: 10;
      opacity: 0.9;
      mix-blend-mode: color-burn;
      stroke-dasharray: 0, 85;
      animation: expandSlackStroke 3.5s infinite; }
      .slack-loading svg line:nth-of-type(1) {
        stroke: #62c2a0; }
      .slack-loading svg line:nth-of-type(2) {
        stroke: #e01958; }
      .slack-loading svg line:nth-of-type(3) {
        stroke: #ecb32c; }
      .slack-loading svg line:nth-of-type(4) {
        stroke: #89d3e0; }

.navbar-nav > li > div > a {
  color: black;
  transition: 0.12s; }

.navbar-nav > li > div > a:hover {
  color: white;
  background-color: #491dac;
  cursor: pointer; }

.navbar-nav > li > a.nav-link.pr-0 {
  /*## !important needed here to override theme styling ##*/
  color: white !important;
  cursor: pointer; }

.navbar-nav > li > a.nav-link.pr-0:hover {
  color: white;
  cursor: pointer; }

div.activity-header > div > button.orbit-button.green > img, div.modal-body > div > div > button > img.orbit-icon.connected {
  transition: all 0.3s;
  filter: invert(57%) sepia(94%) saturate(298%) hue-rotate(110deg) brightness(125%) contrast(91%); }

div.activity-header > div > button.orbit-button.green:hover > img.connected, div.modal-body > div > div > button:hover > img.orbit-icon.connected {
  filter: unset; }

.soft-delete-card {
  background-color: white;
  border-radius: 12px;
  padding: 2rem 3rem;
  width: 100%;
  min-height: 400px;
  box-shadow: 3px 4px 18px -8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .soft-delete-card i {
    font-size: 4rem;
    line-height: 3rem;
    margin: 1rem;
    padding: 2rem;
    border-radius: 50%;
    background-color: #e4e8ff;
    color: #868c95; }
  .soft-delete-card h3 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.3rem;
    max-width: 70%;
    color: #868c95; }

.integration-row:first-child {
  border-top: 1px solid #ddd7d1; }

.integration-row {
  border-bottom: 1px solid #ddd7d1;
  padding: 1.7rem 0; }
  .integration-row .orbit-button {
    padding: 8px 15px; }
  .integration-row .orbit-icon {
    margin-right: 1rem; }

.integration-img {
  height: 30px;
  width: 30px; }

.integration-img.wide {
  width: unset; }
