/************
* Variables *
*************/
:root {
  --red: #ed093c;
  --orange: #fa5d35;
  --yellow: #ffb500;
  --green: #32b38b;
  --blue: #5772e5;
  --purple: #6d2acc;
  --purple-darker: #491dac;
  --purple-lighter: #8635d7;
  --grey: #868c95;
  --light-grey: #ddd7d1; }

/************
* Colours   *
*************/
/************
* Basic     *
*************/
body {
  background: #868c95;
  font-weight: 400;
  font-size: 1rem;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  color: #868c95; }

p {
  margin: 0;
  color: #868c95;
  font-size: 1rem; }

a {
  color: #6d2acc; }

a:hover {
  color: #373940; }

a:visited {
  color: #ddd7d1; }

h1 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  color: #868c95;
  padding: 0;
  margin: 0; }

h2 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0; }

h3 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0; }

h4 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0; }

h5 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0; }

.page {
  overflow-x: hidden; }

.text-center {
  text-align: center; }

.font-w-500 {
  font-weight: 500; }

.pad-150 {
  padding-top: 150px;
  padding-bottom: 150px; }

.interviews-pad {
  padding-top: 125px;
  padding-bottom: 125px; }

.w-100 {
  width: 100%; }

.m-b-15 {
  margin-bottom: 15px; }

.m-b-25 {
  margin-bottom: 25px; }

.m-b-40 {
  margin-bottom: 40px; }

.m-b-50 {
  margin-bottom: 50px; }

.m-b-100 {
  margin-bottom: 100px; }

.m-l-0 {
  margin-left: 0px; }

.m-l-25 {
  margin-left: 25px; }

.m-t-15 {
  margin-top: 15px; }

.m-t-25 {
  margin-top: 25px; }

.m-t-25 {
  margin-top: 30px; }

.m-t-40 {
  margin-top: 40px; }

.m-t-50 {
  margin-top: 50px; }

.m-t-75 {
  margin-top: 75px; }

.m-t-100 {
  margin-top: 100px; }

.bg-white {
  background-color: white; }

.bg-black {
  background-color: black; }

.flex-container {
  display: flex;
  margin-right: 12rem;
  margin-left: 12rem; }

@media only screen and (max-width: 1700px) {
  .flex-container {
    margin-right: 4rem;
    margin-left: 4rem; } }

@media only screen and (max-width: 1200px) {
  .flex-container {
    margin-right: 2rem;
    margin-left: 2rem; } }

.flex-vertical {
  display: flex;
  flex-direction: column; }

.flex-horizontal {
  display: flex;
  flex-direction: row; }

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.justify-between {
  display: flex;
  justify-content: space-between; }

.justify-around {
  display: flex;
  justify-content: space-around; }

.justify-center {
  display: flex;
  justify-content: center; }

.align-between {
  display: flex;
  align-items: space-between; }

.align-around {
  display: flex;
  align-items: space-around; }

.align-center {
  display: flex;
  align-items: center; }

/************
* Buttons   *
*************/
.orbit-button {
  padding: 8px 40px 8px 40px;
  margin: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: white;
  background-color: transparent;
  transition: all 0.4s; }
  .orbit-button .fa-button-icon {
    margin-right: 10px; }

.orbit-button:hover {
  background-color: rgba(0, 0, 0, 0.2); }

.orbit-button.primary {
  background-color: #32b38b;
  border: 1.5px solid #32b38b; }

.orbit-button.primary:hover {
  background-color: #258366;
  border: 1.5px solid #258366; }

.orbit-button.danger,
.orbit-button.delete {
  background-color: #ed093c;
  border: 1.5px solid #ed093c; }

.orbit-button.danger:hover,
.orbit-button.delete:hover {
  background-color: #b2072d;
  border: 1.5px solid #b2072d; }

.orbit-button.green {
  background-color: white;
  color: #32b38b;
  border: 1.5px solid #32b38b; }

.orbit-button.green:hover {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1.5px solid white;
  color: white; }

.orbit-button.white {
  border: 1.5px solid white; }

.orbit-button:disabled {
  opacity: 0.6;
  background-color: #ddd7d1;
  border: 1.5px solid #ddd7d1;
  cursor: not-allowed; }

.orbit-button:disabled:hover {
  background-color: #ddd7d1;
  border: 1.5px solid #ddd7d1; }

/*******************
* Navbar           *
********************/
.orbit-navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #6d2acc;
  z-index: 4;
  height: 73px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; }

.navbar-title {
  margin: 0;
  margin-left: 25px;
  font-size: 1.5rem;
  color: white;
  font-weight: 400;
  font-family: 'Poppins', sans-serif; }

.navbar-user {
  margin: 0;
  margin-right: 12px;
  font-size: 1.3rem;
  color: white;
  font-weight: 300;
  font-family: 'Poppins', sans-serif; }

.navbar-icon {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  background-color: #ffb400; }

.navbar-brand {
  height: 55px; }

.no-photo {
  position: relative; }

.no-photo > i {
  position: absolute;
  top: 9px;
  left: -1px;
  right: 0;
  color: white;
  font-size: 1.5rem;
  text-align: center; }

@media only screen and (max-width: 720px) {
  .orbit-navbar {
    height: 60px; }
  .navbar-title {
    margin: 0;
    margin-left: 30px;
    font-size: 2rem;
    color: white;
    font-weight: 400;
    font-family: 'Poppins', sans-serif; }
  .navbar-user {
    display: none; }
  .navbar-brand {
    height: 38px; }
  .navbar-icon {
    height: 40px;
    width: 40px; } }

/*******************
* Footer           *
********************/
.orbit-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: white;
  align-items: center;
  padding: 75px 0px; }

.orbit-footer > * {
  width: 140px; }

.orbit-footer > img {
  height: 70px; }

.orbit-footer > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

@media only screen and (max-width: 680px) {
  .orbit-footer {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center; }
  .orbit-footer > img {
    margin: 17px 0px; }
  .orbit-footer > * {
    width: unset; } }

/*******************
* Call to Action   *
********************/
.index-header {
  height: 120px;
  padding-top: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1; }

.call-to-action {
  background: #947dc6;
  background: radial-gradient(circle, #491dacc9 0%, #6d2acc 45%);
  height: 850px;
  padding-top: 65px;
  position: relative;
  overflow: hidden; }

.call-to-action.final {
  background: #947dc6;
  background: radial-gradient(circle, #8635d7 0%, #6d2acc 45%);
  height: 725px;
  position: relative;
  overflow: hidden; }

.call-to-action.final > div.flex-container {
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  z-index: 10; }

.call-to-action > div.flex-container {
  position: absolute;
  z-index: 4; }

.call-to-action > div > div {
  max-width: 74.5vw;
  padding-top: 100px; }

.large-text {
  font-size: 5.5rem;
  line-height: 5.3rem;
  margin: 0;
  font-weight: 700;
  letter-spacing: 1px; }

.medium-text {
  font-size: 3rem;
  line-height: 3rem;
  margin: 0;
  font-weight: 600;
  margin-top: 35px; }

.call-to-action-final-text {
  text-align: center;
  font-size: 2.6rem;
  line-height: 3.3rem;
  font-weight: 600; }

img#laptop {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 37vw;
  z-index: 8; }

img#phone {
  position: absolute;
  bottom: 0;
  right: 32vw;
  height: 26vw;
  z-index: 9; }

.fill-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 155px;
  width: 100vw;
  background-image: url(/img/Fill-1.svg);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: cover; }

.fill-2 {
  position: absolute;
  bottom: -60px;
  left: -2px;
  height: 315px;
  width: 100vw;
  background-image: url(/img/Fill-2.svg);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: color-dodge;
  opacity: 0.4; }

.dots {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  background-image: url(/img/dots.svg);
  background-position: top left;
  background-repeat: repeat;
  mix-blend-mode: color-dodge;
  opacity: 0.4; }

/*******************
* What Can We Do   *
********************/
.what-can-we-do {
  font-size: 4rem;
  font-weight: 600;
  line-height: 4.5rem;
  padding-left: 10rem;
  padding-right: 6rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #868c95;
  text-align: left;
  min-width: 860px; }

.we-can {
  font-size: 1.4rem;
  line-height: 2.6rem;
  padding-right: 12rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #222;
  max-width: 190rem;
  text-align: left; }

.icon-text {
  font-size: 2rem; }

/*******************
* Getting Started  *
********************/
.getting-started-container {
  align-items: center;
  padding-top: 10rem; }

.getting-started-container > div {
  width: 85vw;
  margin-top: 3rem; }

.getting-started {
  font-size: 3.1rem;
  font-weight: 600;
  line-height: 3.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #868c95;
  text-align: center; }

.icon-features {
  flex-wrap: wrap; }

.icon-feature {
  max-width: 300px;
  margin-bottom: 30px; }

.icon-size {
  max-width: 94px;
  max-height: 94px; }

.icon-text {
  text-align: center; }

@media only screen and (max-width: 1440px) {
  .call-to-action > div > div {
    max-width: 55.5vw;
    padding-top: 100px; } }

@media only screen and (max-width: 1412px) {
  .icon-features {
    width: 63vw !important; }
  .we-can {
    padding-right: 4rem;
    max-width: 190rem; }
  .what-can-we-do {
    padding-right: 6rem;
    padding-left: 8rem;
    min-width: 770px; } }

@media only screen and (max-width: 1222px) {
  .what-can-we-do {
    min-width: 629px; } }

@media only screen and (max-width: 1030px) {
  .icon-features {
    width: 83vw !important; }
  .what-can-we-do {
    padding-left: 4rem;
    padding-right: 3rem;
    min-width: 560px; }
  .we-can {
    padding-right: 2.5rem; } }

@media only screen and (max-width: 952px) {
  .call-to-action > div > div {
    padding-top: 40px; }
  .call-to-action {
    height: 910px; } }

@media only screen and (max-width: 900px) {
  .call-to-action > div > div {
    padding-top: 100px; }
  .call-to-action {
    height: 1000px; }
  .hiring-process > div {
    flex-wrap: wrap; }
  .what-can-we-do {
    text-align: center;
    padding-left: 3rem;
    padding-right: 3rem; }
  .we-can {
    text-align: center;
    padding-left: 3rem;
    padding-right: 3rem; } }

@media only screen and (max-width: 830px) {
  .call-to-action.final > div > div {
    max-width: 100vw !important; } }

@media only screen and (max-width: 722px) {
  .call-to-action > div > div {
    max-width: 95vw; }
  .icon-features {
    width: 90vw !important; } }

@media only screen and (max-width: 695px) {
  .call-to-action.final {
    height: 925px; }
  .large-text {
    font-size: 4.2rem;
    line-height: 4.2rem; } }

@media only screen and (max-width: 600px) {
  .icon-features {
    width: 100%; }
  .call-to-action {
    height: 1000px; } }

@media only screen and (max-width: 515px) {
  .call-to-action {
    height: 1100px; } }

/*******************
* Revolutionize    *
********************/
.the-future-tablet {
  display: none; }

.the-future {
  padding-top: 150px;
  background-color: white; }

.the-future > div {
  margin-right: 4rem;
  margin-left: 4rem; }

.the-future > div > h1 {
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 600;
  color: #868c95; }

.the-future > div > p {
  font-size: 1.1rem;
  line-height: 2rem;
  padding-top: 50px;
  max-width: 44vw;
  color: #222; }

.revolutionize-container {
  background-image: url(/img/Revolutionize-the-way-you-find.png);
  background-position: center;
  background-repeat: none;
  background-size: cover;
  height: 525px;
  position: relative; }

.revolutionize-container.alt {
  background-image: url(/img/Revolutionize-the-way-you-find2.png) !important;
  background-color: #d3dde4 !important;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 0px; }

.revolutionize-container > div {
  position: absolute;
  right: 100px;
  top: 0px;
  height: 525px; }

.revolutionize-container > div > p {
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 700;
  margin-bottom: 40px;
  color: white;
  text-align: center;
  width: 50vw; }

@media only screen and (max-width: 675px) {
  .revolutionize-container {
    background: #ddd8d2 !important;
    height: unset;
    padding-top: 30px;
    padding-bottom: 50px; }
  .revolutionize-container > div {
    position: initial;
    height: unset;
    padding-top: 10px; }
  .revolutionize-container > div > p {
    width: 80vw; } }

#UI {
  -webkit-box-shadow: -6px -6px 18px -8px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: -6px -6px 18px -8px rgba(0, 0, 0, 0.39);
  box-shadow: -6px -6px 18px -8px rgba(0, 0, 0, 0.39);
  border-top-left-radius: 15px;
  max-width: 820px;
  margin-top: -50px; }

@media only screen and (max-width: 1500px) {
  #UI {
    align-self: center;
    max-width: 630px; }
  .the-future > div > p {
    padding-bottom: 100px; } }

@media only screen and (max-width: 1100px) {
  .the-future {
    display: none; }
  .the-future > div > p {
    max-width: 90vw;
    align-self: center; }
  .the-future > div > h1 {
    padding-top: 70px;
    align-self: center;
    padding-bottom: 20px; }
  .the-future-tablet {
    display: initial;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center; }
  img#UI {
    max-width: unset;
    width: 65%;
    align-self: center;
    margin-top: 40px;
    margin-bottom: 50px; } }

@media only screen and (max-width: 900px) {
  img#UI {
    width: 100%; } }

/*******************
* Interview        *
********************/
.interview-container {
  position: relative;
  min-height: 50vh; }

.interview-header {
  padding-bottom: 125px;
  padding-top: 110px; }

.interview-answer-progress {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 200px;
  padding-top: 75px;
  background-color: #491dac;
  margin-bottom: 150px; }
  .interview-answer-progress .interview-answer-inner {
    display: flex;
    flex-direction: column;
    position: relative; }
    .interview-answer-progress .interview-answer-inner .label-text {
      font-size: 1.2rem;
      line-height: 3rem;
      color: white;
      font-weight: 500;
      margin-top: 10px;
      max-width: 70vw; }
    .interview-answer-progress .interview-answer-inner .answer-progress {
      display: flex;
      flex-direction: row;
      position: relative;
      margin-top: 5px;
      margin-bottom: 20px;
      justify-content: center; }
      .interview-answer-progress .interview-answer-inner .answer-progress .item {
        margin-right: 5px;
        margin-left: 5px;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        cursor: pointer; }
        .interview-answer-progress .interview-answer-inner .answer-progress .item i {
          position: absolute;
          margin-left: 3px;
          margin-top: 3px; }
      .interview-answer-progress .interview-answer-inner .answer-progress .inactive {
        border: 1.5px solid #ddd7d1;
        background-color: #ddd7d1; }
      .interview-answer-progress .interview-answer-inner .answer-progress .active {
        border: 1.5px solid #32b38b;
        background-color: white;
        color: #32b38b; }
      .interview-answer-progress .interview-answer-inner .answer-progress .completed {
        border: 1.5px solid #32b38b;
        background-color: #32b38b;
        color: white; }

.interview-title {
  color: #868c95;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 4.9rem;
  margin: 0; }

.interview-company {
  color: #868c95;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin: 0;
  margin-top: 15px; }

.orbit-interview-card {
  background-color: white;
  position: relative;
  height: 500px;
  width: 1000px;
  -webkit-box-shadow: 5px 5px 21px -2px rgba(0, 0, 0, 0.07);
  box-shadow: 5px 5px 21px -2px rgba(0, 0, 0, 0.07); }

.orbit-interview-card-right {
  background-color: #f9fafd;
  position: absolute;
  right: 0;
  top: 0;
  height: 500px;
  width: 580px;
  transition: all 0.6s; }

.orbit-interview-card-right.dragged-over {
  background-color: #adbae4; }

.orbit-interview-step {
  color: #6c727c;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;
  margin-top: 25px;
  transition: all 0.3s; }

.orbit-interview-step > img {
  margin-right: 25px;
  height: 30px;
  width: 30px; }

.orbit-interview-step > i {
  margin-right: 25px;
  border: 1.5px solid #868c95;
  height: 30px;
  width: 30px;
  border-radius: 50%; }

.orbit-interview-step.active {
  color: #32b38b;
  font-weight: 500; }

.orbit-interview-step.active > i {
  border-color: #32b38b; }

/*******************
* Interview Resume *
********************/
.orbit-dropbox-inner {
  background-color: white;
  padding: 25px 60px 25px 60px;
  transition: all 0.4s; }

.orbit-dropbox-inner.dragged-over {
  background-color: #adbae4; }

.orbit-dropbox-inner.dragged-over > img {
  filter: brightness(0) invert(1); }

.orbit-dropbox-inner.dragged-over > p {
  color: white; }

.orbit-dropbox-inner.dragged-over > input + label {
  color: white;
  border-color: white;
  background-color: transparent; }

.orbit-dropbox-inner > img {
  margin: 0;
  margin-right: 20px; }

.orbit-dropbox-inner > p {
  margin: 0;
  margin-right: 20px;
  font-size: 0.9rem;
  font-weight: 400;
  color: #868c95; }

.orbit-dropbox-inner > input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.orbit-dropbox-inner > input + label {
  margin: 0;
  font-size: 1rem;
  cursor: pointer; }

.orbit-dropbox-inner > input:focus + label,
.orbit-dropbox-inner > input + label:hover {
  background-color: #32b38b !important; }

.orbit-interview-card-left.flex-vertical > button {
  position: absolute;
  bottom: 20px;
  left: 25px;
  width: 350px;
  font-size: 1.1rem; }

.step-1 {
  margin-top: 40px; }

.step {
  left: 0;
  display: flex;
  right: 0;
  align-items: center;
  justify-content: center;
  top: -100px; }

@media only screen and (max-width: 1200px) {
  .orbit-interview-card {
    width: 846px; }
  .orbit-interview-card-right {
    width: 503px; }
  .orbit-interview-card-left.flex-vertical > button {
    width: 273px; } }

@media only screen and (max-width: 855px) {
  .orbit-interview-card {
    width: 97vw;
    height: fit-content; }
  .orbit-interview-card-right {
    width: 100%;
    height: 300px;
    position: initial; }
  .orbit-interview-card-left.flex-vertical > button {
    width: 87vw;
    position: initial;
    margin-top: 50px;
    align-self: center; }
  .orbit-dropbox-inner {
    width: 90vw; } }

@media only screen and (max-width: 445px) {
  .orbit-dropbox-inner {
    justify-content: center; }
  .orbit-dropbox-inner > img {
    display: none; }
  .orbit-dropbox-inner > p {
    display: none; } }

.upload-row {
  align-items: center;
  margin-top: 15px;
  background-color: transparent; }

.upload-row > p {
  color: #868c95;
  margin-bottom: 0; }

.upload-row > p.status-text {
  margin-left: 2px;
  margin-right: 15px;
  font-size: 0.8rem; }

.upload-row > p.file-name {
  margin-right: 15px;
  font-size: 0.8rem; }

.upload-row > .upload-bar {
  transition: all 0.6s;
  width: var(--progress);
  height: 10px;
  border-radius: 20px;
  background-color: #32b38b; }

.upload-row > p.active {
  color: #32b38b; }

/********************
* Interview Confirm *
*********************/
.confirm-details {
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: flex-start; }

.error-list {
  margin-bottom: 5px; }

.error > i {
  margin-right: 15px;
  color: #ed093c; }

.confirm-details > p.explanation {
  margin-top: 50px;
  color: #868c95;
  font-size: 0.9rem;
  margin-bottom: 25px; }

.confirm-details input {
  border: 1px solid rgba(32, 32, 32, 0.05);
  background-color: white;
  padding: 10px 20px 10px 20px; }

.confirm-details input:first-child {
  margin-right: 25px; }

.personal-details {
  margin-bottom: 25px; }

.personal-details > div {
  margin-bottom: 15px; }

.personal-details > div > input {
  max-width: 260px; }

.skills-container {
  margin-top: 25px;
  max-width: 525px;
  margin-left: 0.15rem; }

.skills-container > h2 {
  font-size: 1.4rem;
  color: black;
  margin: 0px 0px 8px 0px;
  font-weight: 400; }

.skills-container > p {
  color: #868c95;
  font-size: 0.9rem;
  line-height: 1rem;
  margin: 0px 0px 18px 0px;
  max-width: 500px; }

.skills-container button {
  font-size: 1.6rem;
  padding: 9px 45px 9px 45px;
  margin: 0;
  line-height: 19px; }

.add-skill {
  align-items: center; }

.add-skill > input {
  width: 100%; }

.skill-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
  max-width: 504px; }

.skill-list > .skill {
  min-width: 100px;
  min-height: 35px;
  border-radius: 17.5px;
  margin: 10px 10px 0px 0px;
  padding: 5px 20px;
  border: 1.5px solid #32b38b;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #32b38b;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.3s; }

.skill-list > .skill:hover {
  background-color: #d7f4eb; }

.begin-interview-text {
  max-width: 255px;
  margin-left: 95px;
  margin-right: 60px;
  margin-top: 10px; }

.begin-interview-text > p {
  font-size: 1rem; }

.begin-interview-text > p:first-child {
  font-size: 1rem; }

/*********************
* Interview Question *
**********************/
.question-card.recording {
  background-color: black; }

.recording-card {
  position: relative;
  transition: all 0.6s;
  padding: 20px;
  box-sizing: border-box; }
  .recording-card .begin-recording {
    padding-left: 100px;
    padding-right: 100px;
    font-size: 1.3rem;
    z-index: 1; }
  .recording-card .record-container.show {
    z-index: 2; }

.question-card {
  width: 950px;
  height: 525px;
  border-radius: 6px;
  padding: 20px;
  background-color: #491dac;
  position: relative;
  transition: all 0.6s; }
  .question-card .question-number {
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: white;
    font-weight: 300;
    text-align: center; }
  .question-card .question-number.finished {
    font-size: 1.15rem;
    z-index: 1; }
  .question-card .question-text {
    font-size: 2.7rem;
    line-height: 3rem;
    color: white;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 50px;
    max-width: 70vw; }
  .question-card .question-text.finished {
    font-size: 2rem;
    line-height: 2.5rem;
    z-index: 1; }
  .question-card .record-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.6s;
    z-index: -1; }
  .question-card .begin-recording {
    padding-left: 100px;
    padding-right: 100px;
    font-size: 1.3rem;
    z-index: 1; }
  .question-card .record-container.show {
    z-index: 2; }
  .question-card #planet {
    position: absolute;
    z-index: 0;
    top: 20px;
    left: 20px;
    opacity: 0.6; }
  .question-card #antenna {
    position: absolute;
    z-index: 0;
    bottom: 0px;
    right: 50px;
    opacity: 0.6; }

.button-holder {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

@media only screen and (max-width: 950px) {
  .question-card {
    width: 95vw;
    height: unset; }
    .question-card .begin-recording {
      width: 87vw;
      font-size: 1.8rem; }
  .question-card.recording {
    background-color: transparent; } }

.login-container {
  min-height: 100vh; }

.left-img {
  max-width: 35vw;
  min-width: 35vw;
  background: url(/img/login.png);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-size: 120%;
  background-repeat: no-repeat; }

.left-img > a {
  display: flex;
  justify-content: center; }

.left-img > a > img {
  margin-top: 50px;
  width: 30%; }

.right-side {
  max-width: 64vw;
  min-width: 64vw;
  background-color: white;
  align-items: center; }

@media only screen and (max-width: 1380px) {
  .left-img {
    background-size: 149%;
    background-position: -50px;
    background-position-y: top; } }

@media only screen and (max-width: 1145px) {
  .left-img {
    max-width: 13vw;
    min-width: 13vw;
    background-size: cover;
    background-position: 40%; }
  .right-side {
    max-width: 87vw;
    min-width: 87vw; } }

.right-side > h1 {
  font-weight: 500;
  color: #6d2acc;
  text-align: center;
  padding: 0px 25px;
  max-width: 720px; }

.right-side > form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.right-side > form > div {
  display: flex;
  flex-direction: column;
  color: #ed093c; }

.login-input {
  border: 1.5px solid #ddd7d1;
  padding: 15px;
  margin: 10px 0px;
  width: 700px;
  transition: all 0.4s;
  font-size: 1rem; }

.login-input.invalid {
  border: 1.5px solid #ed093c; }

.right-side > form > button {
  margin-top: 35px;
  margin-bottom: 15px; }

label.checkbox {
  align-self: flex-start;
  display: inline-flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  flex-direction: row; }

label.checkbox > span {
  color: #60666e;
  padding: 0.5rem 0.25rem;
  margin-left: 8px; }
  label.checkbox > span a:visited {
    color: #491dac; }

label.checkbox > input {
  height: 25px;
  width: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1.5px solid #ddd7d1;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: white;
  cursor: pointer; }

label.checkbox > input:checked + span::before {
  content: '\2713';
  display: block;
  text-align: center;
  color: #868c95;
  position: absolute;
  left: 0.37rem;
  top: 0.6rem; }

label.checkbox > input:active {
  border: 2px solid #34495e; }

.existing-account:visited {
  color: #32b38b; }

@media only screen and (max-width: 820px) {
  .left-img {
    max-width: 0vw;
    min-width: 0vw;
    background-size: cover;
    background-position: 40%; }
  .right-side {
    max-width: 100vw;
    min-width: 100vw; }
  .login-input {
    width: 90vw; }
  .working-for-you {
    text-align: center;
    line-height: 3.4rem; } }

.call-to-action-final-text {
  font-size: 2.5rem;
  line-height: 2.8rem; }

.getting-started {
  font-size: 2.7rem;
  line-height: 2.9rem; }

@media only screen and (max-width: 550px) {
  .revolutionize-container > div > p {
    font-size: 2.2rem;
    line-height: 2.7rem; }
  .the-future > div > h1 {
    font-size: 2.3rem;
    line-height: 2.6rem; }
  .the-future > div > p {
    line-height: 2rem;
    max-width: 100vw;
    min-width: 80vw; }
  .what-can-we-do {
    min-width: unset;
    max-width: 92vw;
    padding: 0; }
  .we-can {
    min-width: unset;
    max-width: 92vw;
    padding: 0; }
  #root > div:nth-child(2) > div.flex-container > div > div,
  #root > div.flex-vertical.call-to-action.final > div.flex-container > div > div {
    flex-direction: column; }
  .call-to-action-final-text {
    font-size: 1.7rem;
    line-height: 2rem; }
  .getting-started {
    font-size: 2.2rem;
    line-height: 2.4rem;
    padding: 0 10px; }
  .getting-started-container.bg-white > div > div > p {
    font-size: 1.2rem;
    line-height: 1.4rem; }
  .what-can-we-do {
    font-size: 2.5rem;
    line-height: 2.7rem; }
  .we-can {
    font-size: 1.4rem;
    line-height: 1.6rem; }
  .getting-started-container.bg-white {
    margin: 0; } }

@media only screen and (max-width: 325px) {
  .flex-container {
    margin: 0 0px; }
  .orbit-button {
    padding: 5px 20px;
    min-width: unset;
    max-width: unset;
    width: 90%; }
  #root > div:nth-child(2) > div.flex-container > div > div > .orbit-button {
    width: 100%; }
  #root > div:nth-child(2) > .flex-container > div > .large-text {
    font-size: 3.6rem;
    line-height: 3.7rem; }
  #root > div:nth-child(2) > .flex-container > div > .medium-text {
    font-size: 2.6rem;
    line-height: 3rem; } }

.orbit-modal,
.orbit-modal-container {
  transition: all 0.4s; }

.orbit-modal-bg {
  transition: opacity 0.4s; }

.orbit-modal-bg {
  position: fixed;
  background: rgba(0, 0, 0, 0.25);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1065; }

.orbit-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 10066;
  background: transparent;
  bottom: unset;
  position: fixed;
  align-self: center;
  top: 1rem;
  left: 0;
  right: 0;
  bottom: 0; }

.orbit-modal {
  position: relative;
  max-width: 95vw;
  max-height: 95vh;
  overflow-y: auto;
  background-color: white;
  border-radius: 8px;
  z-index: 1067;
  padding: 2rem 2rem 0.5rem 2rem;
  min-width: 18rem; }

.orbit-modal > .close {
  position: absolute;
  top: 10px;
  right: 15px; }

.orbit-modal-header {
  margin-bottom: 20px; }
  .orbit-modal-header h2 {
    color: #491dac;
    font-size: 1.5rem;
    line-height: 1.8rem; }

.orbit-modal-body {
  min-width: 340px; }

.orbit-modal-body > form {
  width: 100%; }

.orbit-modal-footer {
  margin-top: 30px; }
  .orbit-modal-footer button {
    padding: 8px 30px 8px 30px;
    font-size: 1.2rem;
    line-height: 1rem;
    margin: 0;
    margin-bottom: 15px;
    width: unset; }

.orbit-modal.closed,
.orbit-modal-bg.closed,
.orbit-modal-container.closed {
  z-index: -5 !important;
  opacity: 0; }

.orbit-modal.open,
.orbit-modal-bg.open,
.orbit-modal-container.open {
  opacity: 1; }

.orbit-modal.closed,
.orbit-modal-container.closed {
  transform: translateY(-100px); }

.orbit-modal.open,
.orbit-modal-container.open {
  transform: translateY(0px); }

.interview-container.embed {
  position: unset;
  min-height: unset; }

.interview-container.embed .step {
  top: 0;
  bottom: 0; }

.fg-white {
  color: white; }

.fg-black {
  color: black; }

.fg-red {
  color: #ed093c; }

.fg-orange {
  color: #fa5d35; }

.fg-yellow {
  color: #ffb500; }

.fg-green {
  color: #32b38b; }

.fg-blue {
  color: #5772e5; }

.fg-purple {
  color: #6d2acc; }

.fg-purple-darker {
  color: #491dac; }

.fg-purple-lighter {
  color: #8635d7; }

.fg-grey {
  color: #868c95; }

.fg-light-grey {
  color: #ddd7d1; }

.fg-transparent {
  color: transparent; }

.bg-white {
  background-color: white; }

.bg-black {
  background-color: black; }

.bg-red {
  background-color: #ed093c; }

.bg-orange {
  background-color: #fa5d35; }

.bg-yellow {
  background-color: #ffb500; }

.bg-green {
  background-color: #32b38b; }

.bg-blue {
  background-color: #5772e5; }

.bg-purple {
  background-color: #6d2acc; }

.bg-purple-darker {
  background-color: #491dac; }

.bg-purple-lighter {
  background-color: #8635d7; }

.bg-grey {
  background-color: #868c95; }

.bg-light-grey {
  background-color: #ddd7d1; }

.bg-transparent {
  background-color: transparent; }

.blog-content {
  padding-bottom: 115px; }

.blog-content > div.container {
  z-index: 2; }

.privacy-text {
  max-width: 50vw;
  margin: 2rem 0;
  text-align: center; }

.pricing-navbar > div:nth-child(2) > div > a:nth-child(2) > button {
  border: 1.5px solid #868c95;
  color: #868c95; }

.pricing-navbar > div:nth-child(2) > div > a:nth-child(1) > button {
  color: #868c95; }

.working-for-you {
  padding-top: 125px; }

.working-for-you > h1 {
  color: #868c95;
  font-weight: 900;
  font-size: 3rem;
  line-height: 3.4rem;
  margin-bottom: 10px; }

.working-for-you > h3 {
  color: #868c95;
  font-weight: 500;
  font-size: 1.5rem;
  margin-top: -10px;
  margin-bottom: 75px; }

.pricing-cards {
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap; }

.pricing-card {
  -webkit-box-shadow: 5px 5px 21px -2px rgba(0, 0, 0, 0.07);
  box-shadow: 5px 5px 21px -2px rgba(0, 0, 0, 0.07);
  background-color: white;
  padding: 10px;
  margin-right: 25px;
  margin-bottom: 12px;
  min-height: 650px;
  min-width: 300px;
  width: 300px;
  position: relative;
  flex: 0 0 20%; }

.best-value {
  background-color: #ffb500;
  border: 2px solid #ffb500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  margin-right: 25px; }

.best-value .pricing-card {
  margin-right: 0px;
  margin-bottom: 0px; }

.best-value > h5 {
  color: white;
  font-weight: 400;
  margin: 0;
  font-size: 0.9rem;
  line-height: 2rem;
  padding: 5px 0px;
  letter-spacing: 2px; }

.pricing-card > h4 {
  text-align: center;
  font-weight: 600;
  font-size: 1.6rem;
  margin-top: 15px;
  color: #868c95;
  text-transform: uppercase;
  letter-spacing: 2px; }

.pricing-card > hr {
  margin: 5px 0px 60px 0px;
  padding: 0px 130px;
  max-width: 80%;
  text-align: center;
  align-self: center; }

.pricing-card > h1 {
  text-align: center;
  font-weight: 500;
  font-size: 3.4rem;
  line-height: 5.5rem;
  align-self: center;
  color: #491dac;
  text-transform: uppercase; }

.pricing-card > h1.contact {
  font-size: 3rem;
  line-height: 3rem;
  padding: 30px 0px;
  margin-bottom: 45px; }

.pricing-card > p {
  text-align: center;
  font-weight: 100;
  font-size: 1.35rem;
  line-height: 1rem;
  margin-top: -15px;
  align-self: center;
  text-align: center;
  color: #868c95;
  padding-bottom: 50px; }

.bullets {
  padding-left: 25px; }

.bullet {
  color: #868c95;
  margin-bottom: 15px;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left; }

.current-tier {
  margin-top: 50px !important;
  color: #000000cf !important;
  font-size: 1.4rem !important;
  font-weight: 600 !important; }

.price-button {
  margin: 0px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 265px !important; }

.the-team {
  display: flex;
  background-color: white;
  padding: 120px 14vw;
  margin-top: 50px;
  transition: all 0.3s; }
  .the-team * {
    transition: all 0.3s; }

.the-team > div.testimonial {
  transform: translateX(0px);
  opacity: 1;
  overflow: hidden; }

.the-team > div.testimonial.animating-out {
  transform: translateX(300px);
  opacity: 0; }

.the-team > div.testimonial.animating-in {
  transform: translateX(-300px);
  opacity: 0; }

.the-team > div > p {
  font-size: 1.4rem;
  line-height: 2.7rem;
  text-align: center;
  color: #868c95; }

.the-team > div > h4:nth-child(2) {
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #868c95;
  font-weight: 700;
  text-align: center;
  margin-top: 35px; }

.the-team > div > h4:nth-child(3) {
  font-size: 1.2rem;
  line-height: 3rem;
  color: #868c95;
  font-weight: 400;
  text-align: center; }

.the-team > div.flex-horizontal.flex-center.justify-around {
  max-width: 20vw;
  align-self: center;
  min-width: 20vw;
  margin-top: 40px; }

div.flex-vertical.flex-center.the-team > div > img {
  cursor: pointer;
  border-radius: 50%;
  height: 70px;
  width: 70px; }

div.flex-vertical.flex-center.the-team > div > img.selected {
  border: 4px solid #ffb400; }

@media only screen and (max-width: 1330px) {
  .pricing-cards {
    padding-left: 25px; } }

@media only screen and (max-width: 500px) {
  .pricing-cards {
    padding-left: 0; }
  .pricing-card {
    margin-right: 0; }
  .best-value {
    margin-right: 0; } }
