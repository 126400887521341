//
// Pricing card
//

.card-pricing {
  &.popular {
    z-index: 1;
    border: 3px solid theme-color('primary') !important;
  }
  
  &--pricing {
    background: #ffffff !important;
    transition: all 300ms linear !important;

    .card-footer { background-color: transparent; }
    
    &:hover {
      background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%) !important;

      .text-info, 
      .price-text,
      .per-month-text,
      .card-body .text-cont { color: #ffffff !important; }

      .card-header { border-bottom: 1px solid rgba(255, 255, 255, 0.1); }

      .card-body {
        .icon {
          background: #ffffff !important;

          .fas {
            color: #8898aa !important;
          }
        }
      }

      .card-footer {
        border-top: 1px solid rgba(255, 255, 255, 0.1);

        .text-light {
          color: #ffffff !important;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .card-header {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .list-unstyled li {
    padding: 0.5rem 0;
    color: $gray-600;
  }
}

.text-cont {
  width: 100%;
  text-align: left;

  span {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .card-pricing {
    &--pricing {
      min-width: calc(50% - 32px);
      margin: 16px !important;

      .price-text {
        font-size: 2em;

        &--popular {
          font-size: 2.4em;
        }
      }

      .text-cont {
        text-align: center;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .card-pricing {
    &--pricing {
      min-width: 0;
      margin: 0 !important;
      background: #ffffff !important;
    
      &:hover {
        z-index: 1;
        transform: scale(1.1);
      }

      .card-body {
        padding: 2em;
      }
    }
  }
}
