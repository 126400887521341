input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  box-shadow: 0 0 0 1000px white inset !important;
}

@keyframes opacity-indicator {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.3;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.video-recorder {
  position: relative;
  video {
    width: 100%;
    max-width: 100%;
    max-height: 450px;
    border-radius: 6px;
  }

  .video-recorder-buttons {
    position: absolute;

    right: 50px;
    bottom: 50px;

    button {
      &.video-recorder-button {
        font-size: 24px;
        width: 64px;
        height: 64px;
        border-radius: 50%;
      }

      &.recording {
        i {
          animation: opacity-indicator 0.8s infinite alternate;
        }
      }

      &.uploading {
        i {
          animation: spin 1s infinite linear;
        }
      }
    }
  }
}

.fallback-video-recorder {
  min-height: 200px;
}

.socials {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-header > img.toggle-sidenav {
  height: 19px;
  width: 25px;
  margin: 15px 2.5rem 15px 1rem;
  cursor: pointer;
}

.social {
  display: inline-block;
  border-radius: 50%;
  padding: 8px;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  max-height: 40px;
  max-width: 40px;
  &:not(:last-child) {
    margin-right: 10px;
  }

  i {
    font-size: 20px;
    width: 24px;
    height: 24px;
    max-height: 24px;
    max-width: 24px;
    text-align: center;
    vertical-align: middle;
    color: #fff;
  }

  transition: background-color 0.3s;

  &.twitter {
    background-color: #55acee;
    &:hover {
      background-color: lighten(#55acee, 4%);
    }
  }

  &.email {
    background-color: #5e5e5e;
    &:hover {
      background-color: lighten(#5e5e5e, 4%);
    }
  }

  &.facebook {
    background-color: #2d4373;
    &:hover {
      background-color: lighten(#2d4373, 4%);
    }
  }

  &.linkedin {
    background-color: #0077b5;
    &:hover {
      background-color: lighten(#0077b5, 4%);
    }
  }
}

.step-container {
  min-height: 650px;
}

.step {
  position: absolute;
  width: 100%;
}

.interview-actions {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  width: 200px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 40%;
  margin-left: -100px;

  .btn-icon {
    width: 200px;
    height: 60px;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .file-upload {
    height: 200px;
    width: 200px;
    border-radius: 100px;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 4px solid #ffffff;
    overflow: hidden;

    background-image: linear-gradient(to bottom, #6b45c5 50%, #ffffff 50%);
    background-size: 100% 200%;
    transition: all 1s;
    color: #ffffff;
    font-size: 100px;

    cursor: pointer;

    input[type='file'] {
      height: 200px;
      width: 200px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }

    &:hover {
      background-position: 0 -100%;

      color: #a045c5;
    }

    &.uploaded {
      background-image: linear-gradient(to bottom, #68e7a1 50%, #ffffff 50%);
      &:hover {
        color: #45c556;
      }
    }
  }

  &.dragged-over {
    .file-upload {
      &:not(.uploaded) {
        background-image: linear-gradient(to bottom, #e7d068 50%, #ffffff 50%);
        &:hover {
          color: #c5a745;
        }
      }
    }
  }
}

.fade-in-fast {
  -webkit-animation: 0.5s ease 0s normal forwards 1 fadein;
  animation: 0.5s ease 0s normal forwards 1 fadein;
}

.fade-in {
  -webkit-animation: 2s ease 0s normal forwards 1 fadein;
  animation: 2s ease 0s normal forwards 1 fadein;
}

.fade-in-slow {
  -webkit-animation: 5s ease 0s normal forwards 1 fadein;
  animation: 5s ease 0s normal forwards 1 fadein;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* NO BORDER SPINNER */
.nb-spinner {
  width: 200px;
  height: 200px;
  margin: 0;
  background: transparent;
  border-top: 4px solid #6b45c5;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

.blink {
  animation: blinker 1.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.pull-top-50 {
  margin-top: -48px;
}

.video {
  width: 100%;
  height: 400px;
}

.horizontal-scroll {
  @media screen and (min-width: 1200px) {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 1em;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #6b45c5;
      border-radius: 8px;
    }
  }
}

.vertical-scroll {
  @media screen and (min-width: 1200px) {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.65em;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #6b45c5;
      border-radius: 8px;
    }
  }
}

.skill-score-bar {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 5px;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  .skill-score-bar__score {
    height: 5px;
    background-color: #6b45c5;
    border-radius: 8px;
    transition: all 300ms ease;
  }
}

.skill-score-text {
  margin-bottom: 0;
  font-size: 10px;
}

.ignore-touch {
  pointer-events: none;
  cursor: pointer;
}

.video-body {
  min-height: 300px;
}

.show {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.list-container {
  min-height: 50px;
  // max-height: 600px;
  // overflow: scroll;
}

.text-inline {
  width: 100%;
}

.no-substitutions{
  max-width: 486.2px;
}
.with-substitutions{
  max-width: 560px;
}
.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-size: 14px;
  padding: 15px;

  .RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
  }

  .RichEditor-editor .public-DraftEditorPlaceholder-root,
  .RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
  }

  .RichEditor-editor .public-DraftEditor-content {
    min-height: 100px;
  }

  .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
  }

  .RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }

  .RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
  }

  .RichEditor-controls {
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
  }

  .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
  }

  .RichEditor-activeButton {
    color: #6b45c5;
  }
}

.rbt {
  flex-grow: 1;
  .rbt-input-hint-container {
    height: 100%;
    .rbt-input {
      height: 100%;
      border: 0;
    }
  }
}

.orbit-input-range {
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none;
  border: none;
  padding: 0;
  background: none;
  box-shadow: none;

  &::-webkit-slider-runnable-track {
    height: 10px;
    border-radius: 6px;
    border: 1px solid transparent;
    background-color: rgba(107, 69, 197, 0.2);
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    margin-top: -6px;
    border-radius: 100%;
    background-color: #6b45c5;
  }

  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
  }

  &--white {
    &::-webkit-slider-runnable-track {
      background-color: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-slider-thumb {
      background-color: #fff;
    }
  }
}

.StripeElement {
  padding: 0.75rem 0.75rem;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
  margin: 0;
  font-size: 0;
}

.embed-content {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
}

.placement-card {
  &:hover {
    cursor: pointer;
  }
}

.pac-container {
  z-index: 1100; // bootstrap modal is 1050
}

.form-error {
  margin-top: 20px;
}

.cards-spinner {
  margin-left: 20px;
}
