.main-content {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .body-container {
    flex-grow: 1;
  }

  .centered-layout {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .centered-content {
      position: relative;
      display: flex;
      flex-direction: column;
      max-width: 1500px;
    }
  }

  // Navbar
  .navbar-top {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  // Container
  .container-fluid {
    @include media-breakpoint-up(md) {
      padding-left: ($main-content-padding-x + $grid-gutter-width / 2) !important;
      padding-right: ($main-content-padding-x + $grid-gutter-width / 2) !important;
    }
  }
}
